import React, { Component } from "react";
import Helmet from "react-helmet";
import ReactPlayer from "react-player";
import Img from "gatsby-image";
import { graphql } from "gatsby";
import css from "./index.module.scss";

class IndexComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      playing: false,
      smokeActive: false,
      smokeDirection: true,
      modalOpened: false,
      trailerUrl: null
    };
    this.handleTrailerClick = this.handleTrailerClick.bind(this);
    this.handleCloseClick = this.handleCloseClick.bind(this);
    this.handleSteamClick = this.handleSteamClick.bind(this);
    this.handleGogClick = this.handleGogClick.bind(this);
    this.ref = this.ref.bind(this);
  }
  handleTrailerClick(url) {
    this.setState({
      smokeActive: true,
      smokeDirection: true,
      trailerUrl: url
    });
    setTimeout(() => {
      this.play();
      this.setState({
        modalOpened: true
      });
    }, 2000);
  }
  handleCloseClick() {
    this.stop();
    this.player.seekTo(0);
    this.setState({
      modalOpened: false,
      smokeDirection: false,
      smokeActive: true
    });
  }
  handleSteamClick() {
    window.location.href =
      "https://store.steampowered.com/app/622720/Weedcraft_Inc/";
  }
  handleGogClick() {
    window.location.href = "https://www.gog.com/game/weedcraft_inc";
  }
  play() {
    this.setState({ playing: true });
  }
  stop() {
    this.setState({ playing: false });
  }
  onReady() {
    this.setState({ playing: true });
  }
  ref(player) {
    this.player = player;
  }
  render() {
    const oneScenarioTrailerUrl = `https://www.youtube.com/watch?v=JydKvlVG1wk`;
    const twoScenarioTrailerUrl = `https://www.youtube.com/watch?v=wt1D2KgMDdc`;
    const dlcTrailerUrl = `https://www.youtube.com/watch?v=xyTakdM_qtI`;
    const dlcFreedomUrl = `https://www.youtube.com/watch?v=Cg2_4ok-rJI`;
    const when = `Available Now`;
    const press = [
      {
        desc: `"Weedcraft Inc. is a tycoon game about developing a marijuana empire, which aspires, and succeeds, to be a game far greater and far more intelligent than anyone would have expected. Coming from Polish studio Vile Monarch, and published by the ever subversive Devolver Digital, Weedcraft Inc. is set to defy your expectations of what it means to play a tycoon game." 9/10`,
        publication: `Digitally Downloaded`,
        reviewUrl: `http://www.digitallydownloaded.net/2019/04/review-weedcraft-inc-pc.html`
      },
      {
        desc: `"The picture that Weedcraft Inc paints -- a complicated, frayed mess of legislation, bribery, selfishness, greed, blackmail and just a touch of true human compassion."`,
        publication: `Endgadget`,
        reviewUrl: null
      },
      {
        desc: `"Are you a cop?"`,
        publication: `IGN`,
        reviewUrl: null
      },
      {
        desc: `"Weedcraft could have gone in a similar direction by trying to glamorize or idolize the rise of a drug dealer, but Alexander and Vile Monarch are going for a more authentic tone, one that is uniquely American."`,
        publication: `Destructoid`,
        reviewUrl: null
      },
      {
        desc: `"Weedcraft Inc. presents a complex set of challenges that reflect the real-world tribulations of the topic."`,
        publication: `Gamereactor`,
        reviewUrl: null
      },
      {
        desc: `"From Main Street to Wall Street Weedcraft Inc helps you build your pot empire this April"`,
        publication: `Tweaktown`,
        reviewUrl: null
      }
    ];
    const review = Math.floor(Math.random() * press.length);
    const {
      trailerUrl,
      playing,
      smokeActive,
      smokeDirection,
      modalOpened
    } = this.state;
    return (
      <div>
        <Helmet defaultTitle={`${when} | Weedcraft Inc`}>
          <meta name="description" content={`${when} | Weedcraft Inc`} />
          <meta
            name="keywords"
            content="Weedcraft, Vile Monarch, Devolver Digital"
          />
          <meta name="author" content="Vile Monarch" />
          <meta property="og:title" content={`${when} | Weedcraft Inc`} />
          <meta property="og:site_name" content="Weedcraft Inc" />
          <meta property="og:url" content="https://www.weedcraftgame.com" />
          <meta
            property="og:description"
            content="Weedcraft Inc explores the business of producing, breeding and selling weed in America."
          />
          <meta
            property="og:image"
            content="https://s3.amazonaws.com/weedcraftgame-prod-hostingbucket/images/weedcraft-facebook.jpg"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@devolverdigital" />
          <meta name="twitter:title" content={`${when} | Weedcraft Inc`} />
          <meta
            name="twitter:description"
            content="Weedcraft Inc explores the business of producing, breeding and selling weed in America."
          />
          <meta
            name="twitter:image"
            content="https://s3.amazonaws.com/weedcraftgame-prod-hostingbucket/images/weedcraft-twitter.jpg"
          />
        </Helmet>
        <main className={css.container}>
          <Img
            id="keyart"
            className={css.keyart}
            style={{
              position: `absolute`,
              top: 0,
              left: 0,
              zIndex: 1,
              width: `100%`,
              height: `90vh`
            }}
            fluid={this.props.data.keyartImage.childImageSharp.fluid}
          />
          <Img
            id="weedcraft"
            className={css.weedcraft}
            fluid={this.props.data.weedcraftImage.childImageSharp.fluid}
          />
          <h2 className={css.coming}>{press[review].desc}</h2>
          <p className={css.publication}>
            <a
              className={css.reviewUrl}
              href={press[review].reviewUrl}
              target="_blank"
            >
              {press[review].publication}
            </a>
          </p>
          <div className={css.group}>
            <div
              onClick={() => this.handleTrailerClick(dlcFreedomUrl)}
              className={`${css.button} ${css.highlight}`}
            >
              &#9654; Freedom Update DLC
            </div>
            <div
              onClick={() => this.handleTrailerClick(dlcTrailerUrl)}
              className={`${css.button} ${css.highlight}`}
            >
              &#9654; Heat Wave DLC Story
            </div>
          </div>
          <div className={css.group}>
            <div
              onClick={() => this.handleTrailerClick(oneScenarioTrailerUrl)}
              className={`${css.button} ${css.highlight}`}
            >
              &#9654; Scenario One Story
            </div>
            <div
              onClick={() => this.handleTrailerClick(twoScenarioTrailerUrl)}
              className={`${css.button} ${css.highlight}`}
            >
              &#9654; Scenario Two Story
            </div>
          </div>
          <div className={css.group}>
            <div onClick={this.handleSteamClick} className={css.button}>
              Buy on Steam
            </div>
            <div onClick={this.handleGogClick} className={css.button}>
              Buy on GoG
            </div>
          </div>
        </main>
        <footer className={css.footer}>
          <a href="http://vilemonarch.com/" className={css.vilemonarch}>
            <figure />
          </a>
          <a
            href="https://discordapp.com/invite/rZqyzZg"
            className={css.discord}
          >
            <figure />
          </a>
          <a href="https://twitter.com/WeedcraftInc" className={css.twitter}>
            <figure />
          </a>
          <a href="https://facebook.com/weedcraftinc" className={css.facebook}>
            <figure />
          </a>
          <a
            href="https://www.instagram.com/weedcraft_inc"
            className={css.instagram}
          >
            <figure />
          </a>
          <a
            href="https://twitter.com/devolverdigital"
            className={css.devolver}
          >
            <figure />
          </a>
        </footer>
        <div
          className={
            modalOpened
              ? `${css.trailerWrapper} ${css.visible}`
              : css.trailerWrapper
          }
        >
          <div className={css.trailerContent}>
            <ReactPlayer
              ref={this.ref}
              url={trailerUrl}
              width="100%"
              height="100%"
              volume={0.4}
              controls
              playing={playing}
              config={{
                youtube: {
                  playerVars: { modestbranding: 1, fs: 0 }
                }
              }}
            />
            <div className={css.trailerClose} onClick={this.handleCloseClick}>
              close
            </div>
          </div>
        </div>
        <div
          className={
            smokeActive
              ? `${css.transitionWrapper} ${css.visible} ${
                  smokeDirection ? css.opening : css.closing
                }`
              : css.transitionWrapper
          }
        >
          <div className={css.transitionContent} />
        </div>
      </div>
    );
  }
}

export default IndexComponent;

export const query = graphql`
  query {
    keyartImage: file(relativePath: { regex: "/keyart/" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    weedcraftImage: file(relativePath: { regex: "/weedcraft-logo/" }) {
      childImageSharp {
        fluid(maxWidth: 1251, traceSVG: { color: "lightgray" }) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    devolverImage: file(relativePath: { regex: "/devolver-logo/" }) {
      childImageSharp {
        fluid(maxWidth: 417) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    vilemonarchImage: file(relativePath: { regex: "/vile-monarch-logo/" }) {
      childImageSharp {
        fluid(maxWidth: 487) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
